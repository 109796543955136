/* 
 * Utilities @ utilities.scss
 */

@import '../Colors/colors.scss';

.clickableText:hover {
    color: $color-secondary;
    text-decoration: underline;
    text-decoration-color: $color-secondary;

    cursor: pointer;
}
/* 
 * PZLongModal @ pzLongModal.scss
 */

 @import '../Colors/colors.scss';

.pzLongModal {
    .pzLongModal-trigger {

    }

    .pzLongModal-modal {
        .pzLongModal-modalBackground {
            z-index: 999;

            position: fixed;

            background-color: #000000;
            opacity: 0.5;
    
            width: 100%;
            height: 100%;
    
            top: 0;
            left: 0;
        }
        
        .pzLongModal-modalWrapper {
            z-index: 1000;

            position: absolute;
            background-color: #ffffff;

            border-radius: 15px;

            max-height: 80%;

            padding-top: 15px;
            padding-bottom: 15px;

            top: 10%;
            left: 4.16%;

            .pzLongModal-close {
                position: absolute;
                top: 5px;
                right: 15px;

                font-weight: 700;
                font-size: 18px;

                cursor: pointer;
            }

            .pzLongModal-modalHeader {
                color: red;
                font-weight: 600;
                font-size: 30px;

                padding-bottom: 15px;
            }

            .pzLongModal-modalBody {
                overflow-y: auto;

                padding-bottom: 15px;
            }

            .pzLongModal-modalFooter {
                padding: 15px;
            }
        }
    }
 }
/* 
 * PZHamburger @ pzHamburger.scss
 */

@import '../Colors/colors.scss';

.pzHamburger {
    padding: 15px;

    &:hover { cursor: pointer; }

    .pzHamburger-lines {
        width: 15px;
        height: 2px;

        background-color: black;

        display: inline-block;

        &:before,
        &:after {
            position: absolute;

            width: 15px;
            height: 2px;
    
            background-color: black;

            content:"";
        }

        &:before {
            top: 20px;
        }

        &:after {
            top: 10px;
        }
    }
}
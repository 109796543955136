/* 
 * NavigationBar @ navigationBar.scss
 */

@import '../Colors/colors.scss';

.navigationBar {
    background-color: white;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    
    width: 100%;
    
    .navigationBarWrapper {
        max-width: 1400px;
        height: 60px;

        margin-left: auto;
        margin-right: auto;

        .navigationBar-hamburger {
            position: absolute;
            top: 25%;
            left: 0px;
            
            padding-right: 0px;
        }

        .navigationBar-appLogo {
            height: 50px;

            padding: 0 15px;

            &:hover { cursor: pointer; }
        }

        .navigationBar-searchBar {
            height: 35px;
            max-width: 650px;

            @media (max-width: 768px) {
                margin-left: 40px;
                padding-right: 10px;
            }

            .navigationBar-dropdown {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }

            .navigationBar-searchBarInput {
                height: 35px;
                padding: 0 15px;

                // &.searchBar-searchInput_noBorderRadius {
                //     border-top-left-radius: 0px;
                //     border-bottom-left-radius: 0px;
                // }
            }
        }

        .navigationBar-links {
            padding: 0 15px;

            .navigationBar-storeLink,
            .navigationBar-couponLink,
            .navigationBar-productLink {
                font-weight: 600;

                &:hover {
                    color: $color-secondary; 

                    cursor: pointer;
                }
            }

            .navigationBar-couponLink,
            .navigationBar-productLink { margin-left: 30px; }
        }
    }

    &.darkenBakcground { z-index: 1; }
}
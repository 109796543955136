/* 
 * app @ app.scss
 */

.app {
   .app-body { 
      max-width: 1400px;

      padding: 15px; 
      margin-top: 60px;
      padding-bottom: 60px;

      height: calc(100vh - 60px);

      overflow-y: scroll;

      &.app-body_adsMarginTop { 
         margin-top: 120px; 
         padding-bottom: 120px;
      }

      // Because of ShareThis App
      .searchResultLayout-actionBar {
         @media (min-width: 1024px) {
            padding-left: 40px;
         }
      }
   }
}

.mobileApp {

   #powzieTopBarPrompt,
   #st-2 { display: none !important; }

   #powzie-headerUpdate { display: block !important; }

   #powzieAppBody { margin-top: 60px !important; }
}



body { 
   background-color: #f1f3f6 !important; 

   overflow-y: hidden;

   
   // This is to remove 000WebHost Advertisement display

   > div:last-of-type {
      height: 0px;
   }

   @media (max-width: 1023px) {
      .st-sticky-share-buttons {
         bottom: 48px !important;
      }
   }
}
/* 
 * StoreSearchPage @ storeSearchPage.scss
 */

@import '../Colors/colors.scss';

.topBarPrompt {
    position: fixed;
    top: 60px;

    height: 50px;
    width: 100%;

    background: white;
    border-top: 1px solid lightgrey;

    .topBarPrompt-closeButton {
        position: absolute;
        left: 0px;

        padding: 10px;
    }

    .topBarPrompt-contentWrapper {

        .topBarPrompt-appLogo {
            max-height: 50px;
        }
    
        .topBarPrompt-message {
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
        }
        
        .topBarPrompt-actionWrapper {
            .topBarPrompt-actionImage {
                max-height: 50px;
            }
        }
    
        .topBarPrompt-closeButton,
        .topBarPrompt-appLogo,
        .topBarPrompt-message,
        .topBarPrompt-actionWrapper {
            padding: 0px 5px;
        }
    }
}
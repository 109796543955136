/* 
 * Coupons @ coupons.scss
 */

@import '../Colors/colors.scss';

.coupons {
    .coupons-body {
        .coupons-bodyLoadMoreButton {
            background-color: #ffffff;
            border-radius: 5px;
            width: fit-content;
            padding: 5px 10px;
            border: 1px solid grey;

            cursor: pointer;
        }
    }
}
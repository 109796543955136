/* 
 * pzTooltip @ pzTooltip.scss
 */

 @import '../Colors/colors.scss';

.pzTooltip {
    z-index: 1;

    position: absolute;

    padding: 10px;

    border-radius: 5px;
    border: 1px solid grey;

    background-color: white;

    &.pzTooltip_top {
        top: -60px;
    }
    &.pzTooltip_right {
        right: calc(-100% - 12px);
    }
    &.pzTooltip_bottom {
        bottom: -41px;
    }
    &.pzTooltip_left {
        left: calc(-100% - 12px);
    }


    .pzTooltip-pointer {
        position: absolute;

        &.pzTooltip-pointer_top {
            top: -11px;

            border-top: 0px;
            border-bottom: 11px solid black;
            border-right: 11px solid transparent;
            border-left: 11px solid transparent;
        }

        &.pzTooltip-pointer_right {
            right: -11px;

            border-top: 0px;
            border-bottom: 11px solid black;
            border-right: 11px solid transparent;
            border-left: 11px solid transparent;
        }

        &.pzTooltip-pointer_bottom {
            bottom: -11px;

            border-top: 0px;
            border-bottom: 11px solid black;
            border-right: 11px solid transparent;
            border-left: 11px solid transparent;
        }

        &.pzTooltip-pointer_left {
            left: -11px;

            border-top: 0px;
            border-bottom: 11px solid black;
            border-right: 11px solid transparent;
            border-left: 11px solid transparent;
        }

        .pzTooltip-pointer-inner {
            position: absolute;

            &.pzTooltip-pointer-inner_top {
                right: -10px;
                top: 1px;
    
                border-top: 0px;
                border-bottom: 10px solid white;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
            }

            &.pzTooltip-pointer-inner_right {
                right: -10px;
                top: 1px;
    
                border-top: 0px;
                border-bottom: 10px solid white;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
            }

            &.pzTooltip-pointer-inner_bottom {
                right: -10px;
                top: 1px;
    
                border-top: 0px;
                border-bottom: 10px solid white;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
            }

            &.pzTooltip-pointer-inner_left {
                right: -10px;
                top: 1px;
    
                border-top: 0px;
                border-bottom: 10px solid white;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
            }
            
        }
    }

    .pzTooltip-exit {
        position: absolute;
        top: -6px;
        right: 4px;

        cursor: pointer;
    }

    .pzTooltip-description {

    }
}
/* 
 * CouponCard @ couponCard.scss
 */

@import '../Colors/colors.scss';

.couponCard {
    padding: 15px;

    .couponCardWrapper {
        border-radius: 5px;
        background-color: white;

        padding: 10px;

        .couponCard-overview {
            padding: 10px;

            .couponCard-overviewName {

            }
            .couponCard-overviewDiscount {
                font-weight: 700;
                font-size: 20px;
                color: red;

                .couponCard-overviewDiscountText {
                    font-size: 12px;
                }
            }
            .couponCard-overviewImage {
                img {
                    max-height: 80px;
                    max-width: 125px;
                }
            }
        }

        .couponCard-detailsDescription {
            .couponCard-detailsDescriptionTrigger {
                text-decoration: underline;
                color: $color-secondary;

                cursor: pointer;
            }

            .couponCard-detailsDescriptionDetails {

            }
        }
        .couponCard-detailsCodeRequirement {

        }

        .couponCard-callToActionButton {

        }
        .couponCard-callToActionExpiration { font-size: 12px; }

        .couponCard-couponCodeWrapper {
            margin-bottom: 5px;

            .couponCard-couponCode,
            .couponCard-couponCodeCopiedMsg {
                background-color: $color-disabled;

                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;

                padding: 5px;
            }

            .couponCard-couponCode {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .couponCard-couponCodeCopiedMsg { 
                background-color: black;
                color: white; 
            }

            .couponCard-couponCodeCopyFunction {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;

                padding: 5px 10px;

                .couponCard-couponCodeCopyFunctionIcon { color: white; }
            }
        }
    }
}
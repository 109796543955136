/* 
 * CouponList @ couponList.scss
 */

@import '../Colors/colors.scss';

.couponList {
    padding: 20px;
    
    .couponList-loadMoreButton {
        background-color: #ffffff;
        border-radius: 5px;
        width: fit-content;
        padding: 5px 10px;
        border: 1px solid grey;

        cursor: pointer;
    }
}
/* 
 * NavigationBar @ navigationBar.scss
 */

@import '../Colors/colors.scss';

.pzButton {
    border-radius: 5px;

    padding: 10px;

    &.secondaryButton { 
        background-color: $color-secondary;

        color: white;
        text-transform: capitalize;
    }

    &:hover { 
        cursor: pointer; 

        background-color: darken($color: $color-secondary, $amount: 10);
    }

    &:active {
        background-color: darken($color: $color-secondary, $amount: 20);
    }
}
/* 
 * Input Text @ inputText.scss
*/

@import '../Colors/colors.scss';

.inputText {
    .inputText-dropdown {

    }
    
    .inputText-inputWrapper {
        position: relative;
        
        .inputText-input {
            &:focus { outline: none; }
        }
    }
}
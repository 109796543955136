/* 
 * Input Text @ inputText.scss
*/

@import '../Colors/colors.scss';

.loadMoreButton {
    .loadMoreButton-loadMoreButton {
        background-color: #ffffff;
        border-radius: 5px;
        width: fit-content;
        padding: 5px 10px;
        border: 1px solid grey;

        cursor: pointer;
    }
}
/* 
 * Search Result @ searchResult.scss
 */

@import '../Colors/colors.scss';

.searchResult {
    .searchResult-actionBar {
        .searchResult-actionBar-storeSelectionIcon {
            cursor: pointer;
            color: $color-primary;
        }

        .searchResult-actionBar-storeListModal {
            .searchResult-storeListModal-store {
                padding: 10px;

                .searchResult-storeListModal-storeLogo {
                    width: 80px;
                    height: 40px;

                    display: inline-block;

                    border-radius: 5px;
                    border: 2px solid grey;
    
                    padding: 5px;

                    cursor: pointer;

                    &:hover,
                    &.searchResult-storeListModal-store_selected { 
                        border: 5px solid $color-primary;     
                    }
                }
            }
        }

        .searchResult-actionBar-storeSelection {
            width: 170px;
        }

        .searchResult-actionBar-sortByDisplay {
        }

        .searchResult-actionBar-price {
            .searchResult-actionBar-minPrice {
                margin-right: 10px;
                margin-left: 5px;
            }

            .searchResult-actionBar-maxPrice {
                margin-left: 5px;
                margin-right: 10px;
            }

            .searchResult-actionBar-priceFilterButton {
                color: white;
                font-weight: bold;

                padding: 3.5px 10px;
            }

            .searchResult-actionBar-minPrice,
            .searchResult-actionBar-maxPrice {
                width: 70px;
            }
        }
    }
}
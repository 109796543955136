/* 
 * Product Search Result @ productSearchResult.scss
 */

 @import '../Colors/colors.scss';

.productSearchResult {
    .productSearchResult-header {
        background-color: white;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);

        width: fit-content;
        margin: auto;
        padding: 15px;

        .productSearchResult-headerTitle {
            .productSearchResult-headerTitleImage { 
                max-height: 100px; 
                max-width: 150px;
            }

            .productSearchResult-headerTitle-text {
                font-weight: 500;
                font-size: 40px;
            }
        }

        .productSearchResult-dropdownWrapper {
            .productSearchResult-categoryDropdownWrapper {
                padding: 5px 0px 5px 15px;

                .productSearchResult-dropdownLabel {

                }

                .productSearchResult-dropdown {
                    width: 200px;
                }
            }
        }
    
        .productSearchResult-countryDropdownWrapper {
            padding-left: 15px;

            .productSearchResult-dropdownLabel {

            }

            .productSearchResult-dropdown {
                width: 200px;
            }
        }
    }
}
/* 
 * Search Result Layout @ searchResultLayout.scss
 */

@import '../Colors/colors.scss';

.searchResultLayout {
    .searchResultLayout-actionBar {
        .searchResultLayout-actionbar-item {
            padding: 5px;
            margin-right: 10px;

            .searchResultLayout-item-label {
                padding-right: 10px;
            }

            .searchResultLayout-item-display {

            }
        }
    }

    .searchResultLayout-result {
        .searchResultLayout-resultItemWrapper {            
            .searchResultLayout-resultItem {

            }
        }        
    }
}
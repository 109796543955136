/* 
 * Input Text @ inputText.scss
*/

@import '../Colors/colors.scss';

.generalLayout {

    .generalLayout-headerUpdate {
        margin-bottom: 15px;

        font-weight: 700;

        background-color: red;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

        @media (min-width: 768px) {
            width: 477.438px
        }
        @media (max-width: 767px) {
            width: 247.438px;
        }

        animation: mymove 1s infinite;

        @keyframes mymove {
            0% { transform: scale(1,1); }
            50% { transform: scale(1.10,1.10); }
            100% { transform: scale(1,1); }
        }
    }

    .generalLayout-header {
        background-color: white;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);

        width: fit-content;
        margin: auto;
        padding: 15px;

        .generalLayout-headerTitle {
            .generalLayout-headerTitleImage { 
                max-height: 100px; 
                max-width: 150px;
            }

            .generalLayout-headerTitle-text {
                font-weight: 500;
                font-size: 40px;
            }
        }
    
        .generalLayout-dropdownWrapper {
            .generalLayout-categoryDropdownWrapper {
                padding: 5px 0px 5px 15px;

                .generalLayout-dropdownLabel {

                }

                .generalLayout-dropdown {
                    width: 200px;
                }
            }
    
            .generalLayout-countryDropdownWrapper {
                padding-left: 15px;

                .generalLayout-dropdownLabel {

                }

                .generalLayout-dropdown {
                    width: 200px;
                }
            }
        }

        .generalLayout-mainStore {
            position: relative;

            border: #0071bc;
            border-width: 10px;
            border-style: outset;
            border-radius: 5px;

            .generalLayout-mainStore-dealsButton {
                background-color: $color-important;
                color: white;
                font-weight: 700;

                animation: mymove 1s infinite;

                @keyframes mymove {
                    0% { transform: scale(1,1); }
                    50% { transform: scale(1.10,1.10); }
                    100% { transform: scale(1,1); }
                }
            }

            .generalLayout-mainStore-tooltip {
                &.generalLayout-mainStore-tooltip_mobilePosition { bottom: -66px; }
                &.generalLayout-mainStore-tooltip_nonMobilePosition { bottom: -90px; }                
            }
        }
    }
}
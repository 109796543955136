/* 
 * Sidebar @ sidebar.scss
 */

@import '../Colors/colors.scss';

.sidebar {
    .sidebar-background {
        position: fixed;

        background-color: #000000;
        opacity: 0.5;
        z-index: 1;

        width: 100%;
        height: 100%;

        top: 0;
        left: 0;
    }

    .sidebar-menu {
        background-color: white;

        position: fixed;
        left: -250px;
        top: 0;
        z-index: 1;

        width: 250px;
        height: 100%;

        transition: transform 0.25s;

        &.sidebar-menu__open {
            transform: translate(250px);
        }
    
        &.sidebar-menu__close {
            transform: translate(0px);	
        }

        .sidebar-menuHeader {
            height: 100px;

            img {
                max-width: 150px;
                max-height: 150px;
            }
        }

        .sidebar-menuBody {
        }
    }
}
/* 
 * Spinner @ spinner.scss
 */

@import '../Colors/colors.scss';

.storeSearchSelect {
    .storeSearchSelect-storesDisplay {
        .storeSearchSelect-storesDisplay-selectedStores {

            border-bottom: 1px solid grey;

            padding-top: 15px;
            padding-bottom: 10px;

            .storeSearchSelect-storesDisplay-title {
                font-size: 16px;
                color: grey;

                padding-bottom: 10px;
            }

            .storeSearchSelect-storesDisplay-storeLogoOuterWrapper {
                padding: 10px;

                width: 100px;
                height: 60px;

                position: relative;

                .storeSearchSelect-storesDisplay-storeLogoWrapper {
                    border: 1px solid black;
                    border-radius: 5px;

                    overflow: hidden;

                    cursor: pointer;

                    .storeSearchSelect-storesDisplay-storeLogo {
                        max-width: 80px;
                        max-height: 40px;    
                    }
    
                    .storeSearchSelect-storesDisplay-closeIcon {
                        position: absolute;
                        top: 3px;
                        right: 3px;
    
                        border: 1px solid black;
                        border-radius: 50%;

                        background-color: white;
                    }
                }
            }
        }

        .storeSearchSelect-storesDisplay-availableStores {
            .storeSearchSelect-storesDisplay-storeLogoWrapper {
                position: relative;

                padding: 10px;

                width: 100px;
                height: 60px;

                opacity: 0.5;

                .storeSearchSelect-storesDisplay-storeLogo {
                    max-width: 80px;
                    max-height: 40px;

                    cursor: pointer;
                }
            }
        }
    }
}
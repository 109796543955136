/* 
 * Product Card @ productCard.scss
 */

 @import '../Colors/colors.scss';
 @import '../Colors/standards.scss';

.productCard {
    padding: 15px;

    .productCardWrapper {
        position: relative;

        width: 200px;
        min-height: 210px;

        @media (min-width: 768px) { height: 270px; }
    
        padding: 15px;
    
        border-radius: 5px;
    
        background-color: white;
    
        cursor: pointer;

        &.productCardWrapper_borderShadow {
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
        }

        &.productCardWrapper_expandHeight { height: 255px; }

        &:hover {
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
        }
    
        .productCard-imageWrapper {
            position: relative;
    
            height: 100px;
            @media (max-width: 768px) { height: 50px; }
    
            margin-bottom: 5px;
    
            cursor: pointer;
    
            .productCard-image {
                max-width: 170px;
                max-height: 100px;

                @media (max-width: 768px) { 
                    max-width: 100px;
                    max-height: 50px; 
                }
            }
        }
    
        .productCard-comparePrice {
            padding: 5px;
            margin-bottom: 5px;
        }

        .productCard-viewDetails {
            text-decoration: underline;
            color: $color-secondary;

            cursor: pointer;
        }
    
        .productCard-name {
            position: relative;
    
            height: 45px;
            overflow: hidden;
    
            font-size: 14px;
    
            // &:after {
            //     content: "...";
    
            //     position: absolute;
            //     top: 25px;
            //     right: 25px;
    
            //     background-color: white;
            // }
        }
    
        .productCard-ratingWrapper {
            .productCard-rating {
                background-color: $color-success;
    
                padding: 0px 3px;
                margin-right: 5px;
    
                border-radius: 5px;
    
                .productCard-rating-value {
                    padding-right: 3px;
    
                    font-size: 12px;
                    color: white;
                    font-weight: 700;
                }
    
                .productCard-rating-icon {
                    color: white;
                    font-size: 10px;
                    font-weight: 700;
                }
            }
    
            .productCard-totalRatings {
                font-size: 12px;
                font-weight: 700;
                color: $text-disabled-color;
            }
        }
    
        .productCard-priceWrapper {
            .productCard-price {
                font-size: 14px;
                font-weight: 500;
    
                margin-right: 5px;
            }
        
            .productCard-prevPrice {
                font-size: 12px;
                color: $text-disabled-color;
                text-decoration: line-through;
    
                margin-right: 5px;
            }
    
            .productCard-discount {
                font-size: 12px;
                color: $text-success-color;
                font-weight: 700;
            }
        }
    
        .productCard-freeShipping {
            .productCard-freeShipping-label {
                padding-right: 5px;
            }
    
            .productCard-freeShipping-label,
            .productCard-freeShipping-icon {
                color: $color-success;
            }
        }
    
        .productCard-totalStores {
    
        }
    
        .productCard-shopNow {
            position: absolute;
            bottom: 0px;
            left: 0px;
    
            width: 100%;
    
            border-top: 1px solid grey;
    
            padding: 5px 10px 5px 10px;
    
            cursor: pointer;
    
            .productCard-shopNow-storeLogo {
                max-width: 150px;
                max-height: 30px;
            }
    
            .productCard-shopNow-rightArrow {
                position: absolute;
                right: 15px;
                top: 33%;
    
                font-size: 12px;
            }
        }
    }
}
/* 
 * StoreCard @ storeCard.scss
 */

@import '../Colors/colors.scss';

.storeCard {
    @media (max-width: 575px) { width: 300px; }
    @media (min-width: 576px) { width: 200px; }

    padding: 15px;

    position: relative;

    .storeCardWrapper {
        background-color: white;

        border-radius: 5px;

        @media (max-width: 575px) { padding: 0px 10px; }
        @media (min-width: 576px) { padding: 10px; }

        &.storeCardWrapper_borderShadow {
            &:hover {
                box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
            }
        }
    
        .storeCard-imageWrapper {
            width: 125px;
            height: 80px;

            padding: 5px;

            .storeCard-image {
                max-width: 115px;
                max-height: 70px;
        
                &:hover { 
                    transform: scale(1.1);
                    cursor: pointer;
                }
            }
        }
    
        .storeCard-name {
    
        }
    
        .storeCard-description {
    
        }
    
        .storeCard-name {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            
            &:hover {
                color: $color-secondary;
                text-decoration: underline;
    
                cursor: pointer;
            }
        }
        
        .storeCard-description {
            @media (min-width: 576px) { margin-top: 5px; }
        }
    }

    .storeCard-tooltip {
    }
}
/* 
 * Notification @ notification.scss
*/

@import '../Colors/colors.scss';

.notification {
    .notification-msg {
        font-size: 18px;
    }
}
/* 
 * SidebarMenu @ sidebarMenu.scss
 */

@import '../Colors/colors.scss';

.sidebarMenu {
    .sidebarMenu-icon {
        display: inline-block;

        padding: 10px;

        cursor: pointer;
    }

    .sidebarMenu-itemName {
        padding: 0 10px;

        cursor: pointer;
    }
}
/* 
 * SearchBar @ searchBar.scss
 */

@import '../Colors/colors.scss';

.searchBar {
    position: relative;
    
    .searchBar-background {
        position: fixed;

        background-color: #000000;
        opacity: 0.5;

        width: 100vw;
        height: 100vh;

        top: 0px;
        left: 0px;
    }

    .searchBar-searchInputWrapper {
        z-index: 0;
        
        .searchBar-search {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            .searchBar-dropdown {

            }
            
            .searchBar-searchInput { 
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;

                padding: 5px 15px;
            }
        }
    
        .searchBar-button {
            padding: 7.5px 10px 8.5px 10px;
            background-color: $color-secondary;
            color: white;
    
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
    
            &:hover { cursor: pointer; }
        }
    }

    .searchBar-searchSuggestions {
        position: absolute;

        width: calc(100% - 1px);

        background-color: white;
        box-shadow: black;

        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .searchbar-searchSuggestionsItem { 
            padding: 10px;

            border-bottom-color: grey;
            border-bottom-width: 1px;

            cursor: pointer;

            &:hover { background-color: darken($color: #ffffff, $amount: 10);}

            &:last-child { 
                border: none; 
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            .searchbar-searchSuggestionsItem-image {
                margin-right: 5px;
            }
        }
    }
}
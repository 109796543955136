/* 
 * Products @ products.scss
 */

 @import '../Colors/colors.scss';

.products {
    height: 100%;

    .products-wrapper {
        .products-header {
            color: $color-primary;
            font-weight: 500;

            @media (min-width: 768px) { font-size: 80px; }
            @media (min-width: 576px) and (max-width: 767px) { font-size: 50px; }
            @media (max-width: 575px) { font-size: 30px; }
        }

        .products-subheader {
            font-weight: 500;
            font-style: italic;
            
            padding-bottom: 50px;
        }
        
        .products-searchEngine {
            max-width: 650px;
    
            .products-searchEngine-input {
                border: 1px solid darkgray;
            }
    
            .products-searchBarButton {
                padding-top: 6px;
            }
        }
    }
}